<template>
    <SectionsNav>
        <template #introduction>
            <a class="nav-link" @click="showSection('presentation')">Обзор</a>
        </template>
        <template #programs>
            <a class="nav-link" @click="showSection('programs')">Программы</a>
        </template>
        <template #campuses>
            <a class="nav-link" @click="showSection('campus')">Кампусы</a>
        </template>
        <template #selectSection>
            <select id="select-mobile-menu" class="form-select form-select-lg" v-model="this.currentSection">
                <option value="presentation">Обзор</option>
                <option value="programs">Программы</option>
                <option value="campus">Кампусы</option>
            </select>
        </template>
    </SectionsNav>
    
    <Obzor v-if="currentSection === 'presentation'" ref="presentation" class="presentation">
        <template #title>
            Обзор
        </template>
        <template #description>  
            Основанная в 1999 году, École Ducasse обладает всемирно признанным передовым опытом в области кулинарного и кондитерского образования. Разделяя опыт и философию культового шеф-повара Алена Дюкасса, школа обучает передовым технологиям на практике.
        </template>
        <template #orientation>
            Bachelor & Master
        </template>
        <template #speciality>
            Culinary
        </template>
    </Obzor>


    <section v-if="currentSection === 'programs'" ref="programs" class="programs">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 title-main">
                            <h1>Программы</h1>
                        </div>
                    </div>
                    
                    <div class="row program-select-wrapper">
                        <div class="col-12 col-lg-4">
                            <select v-model="program" ref="programSelector" class="form-select form-select-lg">
                                <option disabled value="">Выбрать программу</option>
                                <option value="baa">Бакалавриат</option>
                                <option value="kp">Краткосрочные программы</option>
                                <option value="dp">Дипломные программы</option>
                            </select>
                        </div>
                    </div>
                    
                    <Program :url="'/ducasse/ECOLE_DUCASSE_BROCHURE.pdf'" v-if="this.program === 'baa'" id="baa">
                        <template #title>
                            BACHELOR IN CULINARY ARTS
                        </template>
                        <template #duree>
                            2 стажировки по 5 месяцев
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            57.900 евро (за 3 года), включая ланчи и оборудование.
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            март, сентябрь
                        </template>
                    </Program>

                    <Program :url="'/ducasse/ECOLE_DUCASSE_BROCHURE.pdf'" v-if="this.program === 'kp'" id="kp">
                        <template #title>
                            Краткосрочные программы
                        </template>
                        <template #description>
                            Culinary Arts Essentials
                            <br>French Pastry Arts Essentials
                            <br>French Chocolate and Confectionary Arts Essentials
                            <br>Essentiels de la Boulangerie 
                            <br>Arts of Service and Sommelier Essentials 
                        </template>
                        <template #duree>
                            2 месяца
                        </template>
                        <template #language>
                            английский или французский
                        </template>
                        <template #cost>
                            8.900-12.900 евро/год
                        </template>
                        <template #campus>
                            Париж или Иссинго
                        </template>
                        <template #start-time>
                             январь, февраль, май, июнь, август, октябрь.
                        </template>
                    </Program>

                    <Program :url="'/ducasse/ECOLE_DUCASSE_BROCHURE.pdf'" v-if="this.program === 'dp'" id="dp">
                        <template #title>
                            Дипломные программы
                        </template>
                        <template #description>
                            Culinary Arts Diploma - 9 месяцев
                            <br>Expert Diploma in Culinary Arts - 6 месяцев 
                            <br>French Pastry Arts Diploma - 8 месяцев
                            <br>Expert Diploma in French Pastry Arts - 2 месяца. 
                        </template>
                        <template #duree>
                            2-9 месяцев
                        </template>
                        <template #language>
                            английский или французский
                        </template>
                        <template #cost>
                            11.900-31.500 евро
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            январь, февраль, апрель, июнь, сентябрь.
                        </template>
                    </Program>

                </div>
            </div>
        </div>
    </section>

    <CampusMain v-if="currentSection === 'campus'" ref="campus" class="campus">
    </CampusMain>

</template>
<script>
import Program from '@/components/schools/slots/Program.vue'
import CampusMain from '@/components/schools/slots/CampusMain.vue'
import SectionsNav from '@/components/schools/slots/SectionsNav.vue'
import Obzor from '@/components/schools/slots/Obzor.vue'
import Campus from '@/components/schools/slots/Campus.vue'

export default {
    data(){
        return {
            currentSection:"presentation",
            program : ""
        }
    },
    components:{
        Program,
        CampusMain,
        SectionsNav,
        Obzor,
        Campus
    },
    methods:{
        showSection(section){
            this.currentSection = section;
            this.desktopScroll();
        },
        desktopScroll(){
            let scrollToElement = document.querySelector(".menu")
            scrollToElement.scrollIntoView({behavior: "smooth", block: "start"});
        }
    }
}
</script>